import React from 'react'
import OcnHeader from './OcnHeader'
import Footer from './Footer'
import { Card } from '@mui/material'
import { CardContent } from '@mui/material'
import { Grid } from '@mui/material'
import Docpage1 from '../assets/Documenttation_page1.svg'
import Docpage2 from '../assets/Documenttation_page2.svg'
import Docpage3 from '../assets/Documenttation_page3.svg'

const Docs = () => {
    return (
        <div>
        <OcnHeader />
        <Grid container justifyContent = 'center' >
            <Grid item xs={12}>
            <Card sx={{ minWidth: 30 }} >
                <CardContent>
                <div align="center">
                    <img src={Docpage1} alt="Logo" width="85%" />
                </div>
                </CardContent>
            </Card>
            </Grid>
            <Grid item xs={12}>
            <Card sx={{ minWidth: 30 }} >
                <CardContent>
                <div align="center">
                    <img src={Docpage2} alt="Logo" width="85%"/>
                </div>
                </CardContent>
            </Card>
            </Grid>
            <Grid item xs={12}>
            <Card sx={{ minWidth: 30 }} >
                <CardContent>
                <div align="center">
                    <img src={Docpage3} alt="Logo" width="85%"/>
                </div>
                </CardContent>
            </Card>
            </Grid>
            
        </Grid>
        <Footer />
        </div>
    )
}

export default Docs
