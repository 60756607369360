import React from 'react'
import {BrowserRouter as Router, Route} from 'react-router-dom'
// import App from './App';
import Docs from './components/Docs';
import Home from './components/Home';
import About from './components/About';

const routes = () => {
    return (
        <Router>
            <Route exact path="/" component={Home} />
            <Route path={'/About'} component={About}></Route>
            <Route path={'/Docs'} component={Docs}></Route>
            <Route path={'/Home'} component={Home}></Route>
        </Router>
    )
}

export default routes
