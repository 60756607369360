import React from 'react'
import Logo from '../assets/Oneceptron_Logo_SVG_v3.svg';
import AppBar from '@mui/material/AppBar'; 
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { useHistory } from 'react-router'

const OcnHeader = () => {
    const history = useHistory();
     const onclickHome = () => {
    history.push({
      pathname: "/Home" 
    })
  }
  const onclickAbout = () => {
    history.push({
      pathname: "/About" 
    })
  }
  
  const onclickDocs = () => {
    history.push({
      pathname: "/Docs" 
    })
  }
    return (
        <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" style ={{background: '#363740', width: '100%'}}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 1 }}
          >
          <img src={Logo} alt="Logo" width="184" height="40"/>
            
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            
          </Typography>
          <Button color="inherit" onClick={onclickHome}>Home</Button>
          <Button color="inherit" onClick={onclickAbout}>About</Button>
          <Button color="inherit" onClick={onclickDocs}>Docs</Button>
        </Toolbar>
      </AppBar>
      <Toolbar />
      
    </Box>
    )
}

export default OcnHeader
