import React from 'react'
import OcnHeader from './OcnHeader'
import AboutPage from '../assets/Oneceptron_About_page_v3.svg'
import Footer from './Footer'
import { Card } from '@mui/material'
import { CardContent } from '@mui/material'
// import Logo from '../assets/Oneceptron_Logo_SVG_v3.svg';
// import { BottomNavigation } from '@mui/material';
// import BottomNavigationAction from '@mui/material/BottomNavigationAction';

const About = () => {
    
    return (
        <div>
            <OcnHeader />
            <Card sx={{ minWidth: 30 }} >
                <CardContent>
                <div align="center"><img src={AboutPage} alt="About" width="90%" height="50%" /></div>
                </CardContent>
            </Card>
            
            
            <Footer />
        </div>
    )
}

export default About
