import React from 'react'
import OcnHeader from './OcnHeader'
import Footer from './Footer'
import { Card } from '@mui/material'
import { CardContent } from '@mui/material'
import { Grid } from '@mui/material'
import { Typography } from '@mui/material'
// import Docpage from '../assets/Oneceptron_homepage_features.svg'
import AutoAgentsAllocation from '../assets/featureicons/AutoAgentsAllocation_1.svg'
import AutoScriptModification from '../assets/featureicons/AutoScriptModification_1.svg'
import CustomAgentsCapacity from '../assets/featureicons/CustomAgentsCapacity_1.svg'
import MultiVersionSupport from '../assets/featureicons/MultiVersionSupport_4.svg'
import Network from '../assets/featureicons/Network_1.svg'
import OcnAgents from '../assets/featureicons/OcnAgents_1.svg'
import RealTimeUI from '../assets/featureicons/RealTimeUI_2.svg'
import TestManagement from '../assets/featureicons/TestManagement_1.svg'
import WebsocketConnections from '../assets/featureicons/WebsocketConnections_1.svg'

const Home = () => {
    return (
        <div>
            <OcnHeader />
            <Grid container justifyContent = 'center'  >
                <Grid item xs={12} >
                <Card sx={{ minWidth: 30 }} >
                <CardContent>
                <Typography variant="h2" component="div" align="center" color="#7F6000" width="100%" style={{backgroundColor: '#F0EEF0'}} sx={{ flexGrow: 1 }}>
                    Welcome to Oneceptron ! 
                </Typography>
                <Typography variant="h5" component="div" align="center"  width="100%" style={{backgroundColor: '#F0EEF0'}} sx={{ flexGrow: 1 }}>
                   A robust distributed Performance testing solution
                </Typography>
                <Typography variant="h6" component="div" align="center"  width="100%" style={{backgroundColor: '#F0EEF0'}} sx={{ flexGrow: 1 }}>
                    Watch Pre-beta Release Demo now to know more!
                </Typography>
                </CardContent>
                </Card>
                </Grid>  
            </Grid>
            <Grid container justify = 'center' direction="row" alignItems="stretch">
                <Grid item xs={12} sm={3}>
                    <Grid  style={{height: "100%"}}>
                        <Grid item xs={12} >
                            <Card style={{height: "100%"}}>
                            <CardContent>
                                <div align="center">
                                    <img src={RealTimeUI} alt="RealTimeUI" width="90%"/>
                                </div>
                            </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card style={{height: "100%"}}>
                            <CardContent>
                                <div align="center">
                                    <img src={OcnAgents} alt="OcnAgents" width="90%" />
                                </div>
                            </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card style={{height: "100%"}}>
                            <CardContent>
                                <div align="center">
                                    <img src={AutoAgentsAllocation} alt="AutoAgentsAllocation" width="90%"/>
                                </div>
                            </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} >
                    <Grid style={{height: "100%"}}>
                            <Card style={{height: "100%"}}>
                            <CardContent style={{height: "100%"}}>
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/BEN11B5RmZg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
                            </CardContent>
                            </Card>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={3} >
                    <Grid  style={{height: "100%"}}>
                        <Grid  item xs={12} >
                            <Card>
                            <CardContent style={{height: "100%"}}>
                                <div align="center">
                                    <img src={CustomAgentsCapacity} alt="CustomAgentsCapacity" width="90%" />
                                </div>
                            </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} >
                            <Card style={{height: "100%"}}>
                            <CardContent>
                                <div align="center">
                                    <img src={WebsocketConnections} alt="WebsocketConnections" width="90%"/>
                                </div>
                            </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} >
                            <Card style={{height: "100%"}}>
                            <CardContent>
                                <div align="center">
                                    <img src={MultiVersionSupport} alt="MultiVersionSupport" width="90%"/>
                                </div>
                            </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>     
            </Grid>
            <Grid container justify = 'center' direction="row" alignItems="stretch">
                <Grid item xs={12} sm={1.5} >
                </Grid>
                <Grid item xs={12} sm={3} >
                    <Card style={{height: "100%"}}>
                    <CardContent>
                        <div align="center">
                            <img src={Network} alt="Network" width="90%"/>
                        </div>
                    </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={3} >
                    <Card style={{height: "100%"}}>
                    <CardContent>
                        <div align="center">
                            <img src={TestManagement} alt="TestManagement" width="90%"/>
                        </div>
                    </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={3} >
                    <Card style={{height: "100%"}}>
                    <CardContent>
                        <div align="center">
                            <img src={AutoScriptModification} alt="AutoScriptModification" width="90%"/>
                        </div>
                    </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={1.5} >
                </Grid>
            </Grid>
            <Footer />
        </div>
    )
}

export default Home